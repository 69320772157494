html {
  /*height: 100vh !important;*/
  width: 100vw;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

.App {
  height: 100vh;
  width: 100vw;
}

html, body, #app, #app>div {
  height: 100%
}